[
  {
    "name": "Milk",
    "x": 2437,
    "y": -4488,
    "icon": "assets/icons/items/milk.png",
    "maps": ["apotheosis-beta-branch", "regular-main-branch", "regular-beta", "new-game-plus-main-branch"],
    "wiki": "https://noita.wiki.gg/wiki/Milk"
  },
  {
    "name": "Beer",
    "x": 7623,
    "y": -4348,
    "icon": "assets/icons/items/beer.png",
    "maps": ["apotheosis-beta-branch", "regular-main-branch", "regular-beta", "new-game-plus-main-branch"],
    "wiki": "https://noita.wiki.gg/wiki/Beer"
  },

  {
    "name": "Pillars",
    "x": -1572,
    "y": -1640,
    "icon": "assets/icons/places/pillars.png",
    "maps": [
      "apotheosis-beta-branch",
      "regular-main-branch",
      "new-game-plus-main-branch",
      "nightmare-main-branch",
      "regular-beta",
      "purgatory",
      "apotheosis",
      "apotheosis-new-game-plus",
      "apotheosis-tuonela",
      "noitavania",
      "noitavania-new-game-plus",
      "alternate-biomes"
    ],
    "wiki": "https://noita.wiki.gg/wiki/Achievement_Pillars"
  },
  {
    "name": "Ocarina",
    "x": -9984,
    "y": -6418,
    "icon": "assets/icons/items/flute.png",
    "maps": [
      "apotheosis-beta-branch",
      "regular-main-branch",
      "new-game-plus-main-branch",
      "nightmare-main-branch",
      "regular-beta",
      "purgatory",
      "apotheosis",
      "apotheosis-new-game-plus",
      "apotheosis-tuonela",
      "alternate-biomes"
    ],
    "wiki": "https://noita.wiki.gg/wiki/Huilu"
  },
  {
    "name": "Kantele",
    "x": -1633,
    "y": -791,
    "icon": "assets/icons/items/kantele.png",
    "maps": [
      "apotheosis-beta-branch",
      "regular-main-branch",
      "new-game-plus-main-branch",
      "nightmare-main-branch",
      "regular-beta",
      "purgatory",
      "apotheosis",
      "apotheosis-new-game-plus",
      "apotheosis-tuonela",
      "noitavania",
      "noitavania-new-game-plus",
      "alternate-biomes"
    ],
    "wiki": "https://noita.wiki.gg/wiki/Kantele"
  },
  {
    "name": "Evil Eye",
    "x": -2434,
    "y": -203,
    "icon": "assets/icons/items/evil_eye.png",
    "maps": [
      "apotheosis-beta-branch",
      "regular-main-branch",
      "regular-beta",
      "purgatory",
      "apotheosis",
      "apotheosis-tuonela",
      "noitavania",
      "noitavania-new-game-plus",
      "alternate-biomes"
    ],
    "wiki": "https://noita.wiki.gg/wiki/Paha_Silm%C3%A4"
  },
  {
    "name": "Dark Chest",
    "x": 3840,
    "y": 15649,
    "icon": "assets/icons/items/chest_dark.png",
    "maps": [
      "apotheosis-beta-branch",
      "regular-main-branch",
      "new-game-plus-main-branch",
      "nightmare-main-branch",
      "regular-beta",
      "apotheosis",
      "apotheosis-new-game-plus",
      "noitavania",
      "noitavania-new-game-plus",
      "alternate-biomes"
    ],
    "wiki": "https://noita.wiki.gg/wiki/Crystal_Key#Dark_Chest"
  },
  {
    "name": "Coral Chest",
    "x": 11519,
    "y": -4806,
    "icon": "assets/icons/items/chest_light.png",
    "maps": [
      "apotheosis-beta-branch",
      "regular-main-branch",
      "new-game-plus-main-branch",
      "nightmare-main-branch",
      "regular-beta",
      "apotheosis",
      "apotheosis-new-game-plus",
      "noitavania",
      "noitavania-new-game-plus",
      "alternate-biomes"
    ],
    "wiki": "https://noita.wiki.gg/wiki/Crystal_Key#Coral_Chest"
  },
  {
    "name": "Music Stone",
    "x": -3342,
    "y": 3346,
    "icon": "assets/icons/items/musicstone.png",
    "maps": [
      "apotheosis-beta-branch",
      "regular-main-branch",
      "regular-beta",
      "apotheosis",
      "apotheosis-new-game-plus",
      "alternate-biomes"
    ],
    "wiki": "https://noita.wiki.gg/wiki/Kuulokivi"
  },
  {
    "name": "Music Box (Pond)",
    "x": 2799,
    "y": 282,
    "icon": "assets/icons/items/music_machine.png",
    "maps": ["apotheosis-beta-branch", "regular-main-branch", "regular-beta"],
    "wiki": "https://noita.wiki.gg/wiki/Music_Machine"
  },
  {
    "name": "Music Box (Lake)",
    "x": -12188,
    "y": -385,
    "icon": "assets/icons/items/music_machine.png",
    "maps": ["apotheosis-beta-branch", "regular-main-branch", "regular-beta"],
    "wiki": "https://noita.wiki.gg/wiki/Music_Machine"
  },
  {
    "name": "Music Box (Tree)",
    "x": -1919,
    "y": -1366,
    "icon": "assets/icons/items/music_machine.png",
    "maps": ["apotheosis-beta-branch", "regular-main-branch", "regular-beta"],
    "wiki": "https://noita.wiki.gg/wiki/Music_Machine"
  },
  {
    "name": "Music Box (Desert)",
    "x": 14678,
    "y": -35,
    "icon": "assets/icons/items/music_machine.png",
    "maps": ["apotheosis-beta-branch", "regular-main-branch", "regular-beta"],
    "wiki": "https://noita.wiki.gg/wiki/Music_Machine"
  },
  {
    "name": "Karl",
    "x": 3239,
    "y": 2400,
    "icon": "assets/icons/items/karl.webp",
    "maps": [
      "apotheosis-beta-branch",
      "regular-main-branch",
      "regular-beta",
      "apotheosis",
      "apotheosis-new-game-plus",
      "noitavania",
      "noitavania-new-game-plus",
      "alternate-biomes"
    ],
    "wiki": "https://noita.wiki.gg/wiki/Racetrack"
  },
  {
    "name": "Essence Of Spirits",
    "x": -14081,
    "y": 13567,
    "icon": "assets/icons/items/essence_alcohol.png",
    "maps": ["apotheosis-beta-branch", "regular-main-branch", "regular-beta"],
    "wiki": "https://noita.wiki.gg/wiki/Essences#Essence_of_Spirits"
  },
  {
    "name": "Essence Of Air",
    "x": -13056,
    "y": -5377,
    "icon": "assets/icons/items/essence_air.png",
    "maps": ["apotheosis-beta-branch", "regular-main-branch", "regular-beta"],
    "wiki": "https://noita.wiki.gg/wiki/Essences#Essence_of_Air"
  },
  {
    "name": "Essence Of Fire",
    "x": -14062,
    "y": 370,
    "icon": "assets/icons/items/essence_fire.png",
    "maps": ["apotheosis-beta-branch", "regular-main-branch", "regular-beta"],
    "wiki": "https://noita.wiki.gg/wiki/Essences#Essence_of_Fire"
  },
  {
    "name": "Essence Of Water",
    "x": -5376,
    "y": 16640,
    "icon": "assets/icons/items/essence_water.png",
    "maps": ["apotheosis-beta-branch", "regular-main-branch", "regular-beta"],
    "wiki": "https://noita.wiki.gg/wiki/Essences#Essence_of_Water"
  },
  {
    "name": "Essence Of Earth",
    "x": 16128,
    "y": -1792,
    "icon": "assets/icons/items/essence_laser.png",
    "maps": ["regular-main-branch", "regular-beta"],
    "wiki": "https://noita.wiki.gg/wiki/Essences#Essence_of_Earth"
  },
  {
    "name": "Essence Of Earth",
    "x": 23808,
    "y": -1793,
    "icon": "assets/icons/items/essence_laser.png",
    "maps": ["apotheosis-beta-branch"],
    "wiki": "https://noita.wiki.gg/wiki/Essences#Essence_of_Earth"
  },
  {
    "name": "Rainbow Trail Spell",
    "x": -14002,
    "y": -2860,
    "icon": "assets/icons/items/rainbow_trail.png",
    "maps": ["apotheosis-beta-branch", "regular-main-branch", "regular-beta"],
    "wiki": "https://noita.wiki.gg/wiki/Rainbow_Trail"
  },
  {
    "name": "Essence Eater (Desert)",
    "x": 12569,
    "y": 16,
    "icon": "assets/icons/overlay-toggles/icon-orbs.webp",
    "maps": ["apotheosis-beta-branch", "regular-main-branch", "regular-beta"],
    "wiki": "https://noita.wiki.gg/wiki/Essence_Eater"
  },
  {
    "name": "Essence Eater (Snow Wasteland)",
    "x": -6884,
    "y": -169,
    "icon": "assets/icons/overlay-toggles/icon-orbs.webp",
    "maps": ["apotheosis-beta-branch", "regular-main-branch", "regular-beta"],
    "wiki": "https://noita.wiki.gg/wiki/Essence_Eater"
  }
]
