[
  {
    "name": "Kivi",
    "aliases": ["Rock Boss", "Stone Boss", "Sky Boss"],
    "x": 7375,
    "y": -4539,
    "maps": ["regular-main-branch", "regular-beta", "apotheosis-beta-branch"],
    "url": "https://noitamap.com/?map=regular&x=7375&y=-4539&zoom=823",
    "icon": "assets/icons/bosses/kivi.png",
    "wiki": "https://noita.wiki.gg/wiki/Kivi"
  },
  {
    "name": "Kolmisilmän Koipi",
    "aliases": ["Pyramid Boss", "Three-Eye's Legs"],
    "x": 9984,
    "y": -786,
    "maps": ["regular-main-branch", "regular-beta", "apotheosis-beta-branch"],
    "url": "https://noitamap.com/?map=regular&x=9984&y=-786&zoom=823",
    "icon": "assets/icons/bosses/legs.png",
    "wiki": "https://noita.wiki.gg/wiki/Kolmisilm%C3%A4n_koipi"
  },
  {
    "name": "Syväolento",
    "aliases": ["Leviathan", "Creature of the Deep"],
    "x": -14024,
    "y": 9994,
    "maps": ["regular-main-branch", "regular-beta", "new-game-plus-main-branch", "apotheosis-beta-branch"],
    "url": "https://noitamap.com/?map=regular&x=-14024&y=9994&zoom=987",
    "icon": "assets/icons/bosses/leviathan.png",
    "wiki": "https://noita.wiki.gg/wiki/Syv%C3%A4olento"
  },
  {
    "name": "Veska, Molari, Mokke, Seula",
    "aliases": ["Door boss", "Gate Guardian", "Triangle boss"],
    "x": 2837,
    "y": 11562,
    "maps": ["regular-main-branch", "regular-beta", "apotheosis-beta-branch"],
    "url": "https://noitamap.com/?map=regular&x=2837&y=11562&zoom=812",
    "icon": "assets/icons/bosses/door.png",
    "wiki": "https://noita.wiki.gg/wiki/Gate_Guardian"
  },
  {
    "name": "Suomuhauki",
    "aliases": ["Dragon", "Scale Pike"],
    "x": 2347,
    "y": 7444,
    "maps": ["regular-main-branch", "regular-beta", "apotheosis-beta-branch"],
    "url": "https://noitamap.com/?map=regular&x=2347&y=7444&zoom=917",
    "icon": "assets/icons/bosses/dragon.png",
    "wiki": "https://noita.wiki.gg/wiki/Suomuhauki"
  },
  {
    "name": "Limatoukka",
    "aliases": ["Tiny", "Slime Maggot", "Slime Caterpillar"],
    "x": 14904,
    "y": 16428,
    "maps": ["regular-main-branch", "regular-beta", "new-game-plus-main-branch", "apotheosis-beta-branch"],
    "url": "https://noitamap.com/?map=regular&x=14904&y=16428&zoom=1022",
    "icon": "assets/icons/bosses/tiny.png",
    "wiki": "https://noita.wiki.gg/wiki/Limatoukka"
  },
  {
    "name": "Kolmisilmän sydän",
    "aliases": ["Meat Boss", "Three-Eye's Heart"],
    "x": 6667,
    "y": 8448,
    "maps": ["regular-main-branch", "regular-beta", "apotheosis-beta-branch"],
    "url": "https://noitamap.com/?map=regular&x=6667&y=8448&zoom=770",
    "icon": "assets/icons/bosses/heart.png",
    "wiki": "https://noita.wiki.gg/wiki/Kolmisilm%C3%A4n_syd%C3%A4n"
  },
  {
    "name": "Ylialkemisti",
    "aliases": ["Alchemist Boss", "High Alchemist"],
    "x": -4840,
    "y": 851,
    "maps": ["regular-main-branch", "regular-beta", "apotheosis-beta-branch"],
    "url": "https://noitamap.com/?map=regular&x=-4840&y=851&zoom=796",
    "icon": "assets/icons/bosses/alchemist.png",
    "wiki": "https://noita.wiki.gg/wiki/Ylialkemisti"
  },
  {
    "name": "Kolmisilmä",
    "aliases": ["Kolmi", "Three-Eye"],
    "x": 3556,
    "y": 13026,
    "maps": ["regular-main-branch", "regular-beta", "new-game-plus-main-branch", "apotheosis-beta-branch"],
    "url": "https://noitamap.com/?map=regular&x=3556&y=13026&zoom=849",
    "icon": "assets/icons/bosses/kolmi.png",
    "wiki": "https://noita.wiki.gg/wiki/Kolmisilm%C3%A4"
  },
  {
    "name": "Kolmisilmän silmä",
    "aliases": ["Mecha Kolmi", "Three-Eye's Eye"],
    "x": 13987,
    "y": 11123,
    "maps": ["regular-main-branch", "regular-beta", "apotheosis-beta-branch"],
    "url": "https://noitamap.com/?map=regular&x=13987&y=11123&zoom=875",
    "icon": "assets/icons/bosses/eye.png",
    "wiki": "https://noita.wiki.gg/wiki/Kolmisilm%C3%A4n_silm%C3%A4"
  },
  {
    "name": "Toveri",
    "aliases": ["Friend Boss", "Friend"],
    "x": 25360,
    "y": 4341,
    "maps": ["regular-main-branch", "regular-beta"],
    "url": "https://noitamap.com/?map=regular&x=25360&y=4341&zoom=917",
    "icon": "assets/icons/bosses/friend.png",
    "wiki": "https://noita.wiki.gg/wiki/Toveri"
  },
  {
    "name": "Toveri",
    "aliases": ["Friend Boss", "Friend"],
    "x": 8454,
    "y": 5971,
    "maps": ["apotheosis-beta-branch"],
    "url": "https://noitamap.com/?map=regular&x=25360&y=4341&zoom=917",
    "icon": "assets/icons/bosses/friend.png",
    "wiki": "https://noita.wiki.gg/wiki/Toveri"
  },
  {
    "name": "Mestarien mestari",
    "aliases": ["The Master of Masters", "Grand Master", "Wizard Boss"],
    "x": 12573,
    "y": 15178,
    "maps": ["regular-main-branch", "regular-beta", "apotheosis-beta-branch"],
    "url": "https://noitamap.com/?map=regular&x=12573&y=15178&zoom=796",
    "icon": "assets/icons/bosses/master.png",
    "wiki": "https://noita.wiki.gg/wiki/Mestarien_mestari"
  },
  {
    "name": "Unohdettu",
    "aliases": ["The Forgotten", "Ghost Boss"],
    "x": -11515,
    "y": 13123,
    "maps": ["regular-main-branch", "regular-beta", "apotheosis-beta-branch"],
    "url": "https://noitamap.com/?map=regular&x=-11515&y=13123&zoom=744",
    "icon": "assets/icons/bosses/forgotten.png",
    "wiki": "https://noita.wiki.gg/wiki/Unohdettu"
  },
  {
    "name": "Sauvojen tuntija",
    "aliases": ["Bridge Boss", "Connoisseur of Wands", "Squid Boss", "Pit Boss", "Wand Boss"],
    "x": 4165,
    "y": 889,
    "maps": ["regular-main-branch", "regular-beta", "apotheosis-beta-branch"],
    "url": "https://noitamap.com/?map=regular&x=4165&y=889&zoom=970",
    "icon": "assets/icons/bosses/bridge.png",
    "wiki": "https://noita.wiki.gg/wiki/Sauvojen_tuntija"
  },
  {
    "name": "Tapion vasalli",
    "aliases": ["Deer Boss", "Tapio's Vassal", "Island Boss"],
    "x": -13670,
    "y": 134,
    "maps": ["regular-main-branch", "regular-beta", "new-game-plus-main-branch", "apotheosis-beta-branch"],
    "url": "https://noitamap.com/?map=regular&x=-13670&y=134&zoom=796",
    "icon": "assets/icons/bosses/deer.png",
    "wiki": "https://noita.wiki.gg/wiki/Tapion_vasalli"
  }
]
